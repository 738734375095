@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("@coredottoday-web/shared/styles/global.css");
@import url("@coredottoday-web/editor/lexical/index.css");
@import url("katex/dist/katex.min.css");

@layer base {
  ul,
  ol {
    list-style: revert;
  }
}

@layer components {
  .menu-card {
    @apply block rounded-sm stroke-slate-700 p-[0.4rem] font-normal text-slate-700 transition duration-300;
  }
  .menu-card:hover {
    @apply cursor-pointer bg-gray-50 stroke-slate-800 font-medium text-slate-800;
  }
  .menu-card:disabled {
    @apply cursor-not-allowed stroke-slate-500 text-slate-500;
  }
  .menu-card:disabled:hover {
    @apply cursor-not-allowed bg-white stroke-slate-500 font-normal text-slate-500;
  }
  .menu-card-active {
    @apply block cursor-pointer rounded-sm bg-blue-50 stroke-blue-700 p-[0.4rem] font-medium text-blue-700 transition duration-300;
  }
  .link-card {
    @apply transition duration-300 visited:text-fuchsia-900;
  }
  .link-card:hover {
    @apply cursor-pointer text-blue-700;
  }
  .link-text {
    @apply transition duration-300;
  }
  .link-text:hover {
    @apply cursor-pointer text-blue-700;
  }
  .modal-2-btn {
    @apply mt-4 flex items-center justify-end gap-3;
  }
}

.sortable-chosen {
  background-color: #f3f4f6;
  border: 1px solid #d1d5db;
  border-radius: 3px;
  transition: all 0.2s ease;
}

.sortable-ghost {
  opacity: 0.5;
  border-top: 1px solid;
  transition: all 0.2s ease;
}

.link-animation {
  transition: color 0.3s ease-in-out, text-decoration 0.3s ease-in-out;
  text-decoration: underline;
}

.link-animation:hover {
  color: #ff6b81;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 3px;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0px 3px 5px rgba(0, 0, 0, 0.04);
  --tw-shadow-colored: 0px 3px 5px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

/* Styles for the markdown */
.markdown-body {
  box-sizing: border-box;
  margin: 0 auto;
  background-color: transparent !important;
}

.markdown-body ol {
  list-style: auto;
}

.markdown-body ul {
  list-style-type: disc;
}

.markdown-body ul ul {
  list-style-type: circle;
}

.markdown-body ul ul ul {
  list-style-type: square;
}

.markdown-body li {
  white-space: normal;
}

.markdown-body li > p {
  display: inline;
}

.markdown-body pre:has(> div.code-wrapper) {
  background-color: transparent;
  padding-left: 0;
  max-height: unset;
}

.markdown-body pre:has(> code) {
  max-height: unset;
}

.markdown-body > div > p {
  margin-bottom: 0;
}
